<div class="page__headline">
    <span eciTranslate="zip-code-delivery.title">Zip Code Delivery</span>
</div>

<div class="page__container zip_code_range_container">
    <eci-alert-error></eci-alert-error>
    <div>
        <dx-file-uploader
            #fileUploader
            uploadUrl=""
            [multiple]="false"
            accept=".xlsx"
            allowCanceling="true"
            uploadMode="useForm"
            id="fileUploaderId"
            (onValueChanged)="uploadFile($event)"
            [(value)]="fileName"
            [width]="400"
        ></dx-file-uploader>
        <dx-button (onClick)="createZipCodeDeliveryFromFile()" [hidden]="!fileToUpload"
                   [text]="this.translateService.get('app.masterArticleJob.fileUploader.create')"
                   type="success" useSubmitBehavior="false" class="saveButton"></dx-button>
        <dx-button (onClick)="changeDatasource()" [hidden]="!fileToUpload"
                   [text]="this.translateService.get('app.masterArticleJob.fileUploader.search')"
                   type="success" useSubmitBehavior="false" class="searchButton" style="margin: 5px"></dx-button>
        <dx-button (onClick)="resetFileUploader()" [hidden]="!fileToUpload"
                   [text]="this.translateService.get('app.masterArticleJob.fileUploader.remove')"
                   class="clearButton">
        </dx-button>
    </div>
    <dx-data-grid id="dataGrid" #dataGrid
                  (onRowInserted)="successNotification()"
                  (onRowUpdated)="successNotification()"
                  (onToolbarPreparing)="onToolbarPreparing($event)"
                  (onEditorPrepared)="onEditorPreparing($event)"
                  (onInitNewRow)="onNewEntry($event)"
                  (onSelectionChanged)="onSelectionChanged()"
                  (onOptionChanged)="onOptionChanged($event)"
                  (onContentReady)="onContentReady()"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [cellHintEnabled]="true"
                  [columnAutoWidth]="true"
                  [dataSource]="dataSource"
                  [(selectedRowKeys)]="selectedRows"
                  [hoverStateEnabled]="true"
                  [remoteOperations]="true"
                  [showBorders]="true"
                  [showColumnLines]="true"
                  [showRowLines]="true"
                  [wordWrapEnabled]="true"
                  [keyExpr]="'zipCode'"
    >
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager
            [visible]="true"
            [allowedPageSizes]="[10, 20, 50, 100]"
            [showInfo]="true"
            [showPageSizeSelector]="true"
            [showNavigationButtons]="true">
        </dxo-pager>
        <dxo-pager [showInfo]="true"></dxo-pager>
        <dxo-state-storing
            [enabled]="true"
            type="custom">
        </dxo-state-storing>
        <dxo-selection
            [showCheckBoxesMode]="'always'"
            [allowSelectAll]="false"
            [selectAllMode]="'page'"
            [deferred]="false"
            mode="multiple"
        ></dxo-selection>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-editing
            [allowAdding]="true"
            [allowDeleting]="true"
            [allowUpdating]="true"
            [useIcons]="true"
            mode="form"
        >
            <dxo-form [colCount]="1"
                      [elementAttr]="{ class: 'zip-code-delivery_edit' }">
                <dxi-item dataField="zipCode" dataType="string">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule type="pattern" pattern="^[0-9]+$"
                                         message="Zip Code should not contain characters">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="description" dataType="string">
                    <dxi-validation-rule
                        [max]="100"
                        type="stringLength"
                    ></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="deliveryDays" dataType="integer">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule type="pattern" pattern="^[0-9]+$"
                                         message="Must be a number">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item
                    [caption]="'zip-code-delivery.properties.shipmentDay' | translate"
                    dataField="shipmentDay"
                    dataType="string">
                </dxi-item>
                <dxi-item
                    [caption]="'zip-code-delivery.properties.isIslandDeliveryEnabled' | translate"
                    dataField="isIslandDeliveryEnabled"
                    dataType="boolean">
                </dxi-item>
            </dxo-form>
        </dxo-editing>

        <dxi-column [caption]="'zip-code-delivery.properties.zipCode' | translate"
                    [minWidth]="250"
                    allowEditing="true"
                    alignment="left"
                    dataField="zipCode"
                    dataType="string"/>
        <dxi-column
            [caption]="'zip-code-delivery.properties.deliveryDays' | translate"
            [minWidth]="150"
            alignment="left"
            dataField="deliveryDays"
            dataType="string"/>
        <dxi-column
            [caption]="'zip-code-delivery.properties.description' | translate"
            [minWidth]="250"
            dataField="description"
            dataType="string"/>
        <dxi-column
            [caption]="'zip-code-delivery.properties.shipmentDay' | translate"
            dataField="shipmentDay"
            dataType="string"
            editCellTemplate="shipmentDayBox">
            <div *dxTemplate="let cellInfo of 'shipmentDayBox'">
                <dx-select-box
                    [items]="weekdays"
                    [value]="getValue(cellInfo)"
                    [showDropDownButton]="true"
                    [showClearButton]="true"
                    [searchEnabled]="true"
                    (onValueChanged)="onValueChanged(cellInfo, $event)"
                    (onSelectionChanged)="cellInfo.component.updateDimensions()">
                </dx-select-box>
            </div>
        </dxi-column>
        <dxi-column
            [caption]="'zip-code-delivery.properties.isIslandDeliveryEnabled' | translate"
            [minWidth]="150"
            dataField="isIslandDeliveryEnabled"
            dataType="boolean"/>

        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{this.getTotalRowCount()}}</h2>
                <span class="name">{{this.translateService.get('zip-code-delivery.toolbar.selectedZipCodes')}}</span>
            </div>
        </div>
    </dx-data-grid>
</div>
